import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import { useAuth } from "../components/AuthContext";
import axios from "axios";

// import { useSession } from "../components/SessionProvider";
// import "../chatwindow.css";

const NavigationBar: React.FC = () => {
  // const userSession = useSession() || {};
  const { session } = useAuth();

  // if (session) {
  //   const fetchUserId = async () => {
  //     try {
  //       const urlEnv = `${process.env.REACT_APP_SERVER_ACCESS}get-user-id`;

  //       const response = await axios.post(urlEnv, {
  //         email: session.user.email,
  //       });

  //       const user_id = response.data.user_id;
  //       sessionStorage.setItem("user_id", user_id);
  //       // console.log("User ID:", user_id);
  //     } catch (error) {
  //       // Handle errors
  //       console.error("Error fetching user ID:", error);
  //     }
  //   };
  //   fetchUserId();
  // }
  return (
    <nav className="bg-gray-900 text-white p-4 flex justify-between items-center">
      <div className="flex items-center">
        <a href="/">
          <img src={logo} alt="Logo" className="h-8 w-8" />
        </a>
      </div>
      <div className="flex space-x-4">
        {/* <a href="#" className="hover:underline">
          Blog
        </a>
        <a href="#" className="hover:underline">
          Community
        </a>
        <a href="#" className="hover:underline">
          Research
        </a>
        <a href="#" className="hover:underline">
          Contact Us
        </a> */}

        {!session && (
          <a href="/Signup" className="hover:underline">
            Signup
          </a>
        )}
        {!session && (
          <a href="/" className="hover:underline">
            Login
          </a>
        )}
      </div>
    </nav>
  );
};

export default NavigationBar;
