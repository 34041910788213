// src/components/Sidebar.tsx
import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";
import { ChatHistory, Message } from "./SendMessage";
// import 'src/chatwindow.css';
import writingImage from "src/assets/writing.png";
import settingImage from "src/assets/settings.png";
import logoutImage from "src/assets/logout.png";
import starImage from "src/assets/star.png";
import moreImage from "src/assets/more.png";
import { useNavigate } from "react-router-dom";
import * as Popover from "@radix-ui/react-popover";
import "src/morebutton.css";
// import { useSession } from "./SessionProvider";
import axios from "axios";
import * as Dialog from "@radix-ui/react-dialog";
import SubscriptionPanel from "../components/SubscriptionPanel";
import crossicon from "src/assets/cross-2.svg";
import "src/modal.css";
import { useAuth } from "./AuthContext";
import { supabase } from "./SupabaseClient";
import SubsPanel from "../components/SubscriptionPanel";
import { useAppContext } from "./LLMContext";

const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

export function Image({ source, alternate }) {
  return <img className="image-size" src={source} alt={alternate} />;
}

export interface SidebarProps {
  chats: {
    // thread_id: number;
    id: number;
    title: string;
  }[];
  activeChatId: number | null;
  startNewChat: () => void;
  loadChat: (id: number) => void;
  deleteChat: (id: number) => void;
}

interface Chat {
  id: number;
  title: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  chats, // = [{ id: 1, title: "Chat 1" }],

  activeChatId,
  startNewChat,
  loadChat,
  deleteChat,
}) => {
  const { session, setSession } = useAuth();
  const user_id = session?.user?.user_metadata.user_id;

  if (activeChatId === null) {
    activeChatId = chats.length - 1;
  }

  const navigate = useNavigate();

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      setSession(null);
      localStorage.removeItem("supabaseSession");
      // sessionStorage.removeItem("user_id");
      navigate("/");
    }
  };
  const hasCreatedInitialChat = useRef(false);
  useEffect(() => {
    if (chats.length > 0 && !hasCreatedInitialChat.current) {
      // Automatically load the last chat or whichever chat you want
      loadChat(chats[chats.length - 1].id);
      hasCreatedInitialChat.current = true;
      console.log("loaded");
    }
  }, [chats, activeChatId, loadChat]);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.post(`${urlEnv}fetch-credits-history`, {
        user_id: user_id,
      });
      setData(response.data);
    } catch (err) {
      setError(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    // Options for formatting the date
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Return the formatted date
    return date.toLocaleDateString("en-US", options);
  };
  return (
    <div className="sidebar">
      <button className="new-chat-button left-margin-t" onClick={startNewChat}>
        <Image source={writingImage} alternate={"writingImage"} />
      </button>
      <div className="chat-history">
        {chats.map((chat) => (
          <div
            key={chat.id}
            className={`sidebar-chats ${
              chat.id === activeChatId ? "active" : ""
            }`}
          >
            <div
              key={chat.id}
              className={`chat-history-item ${
                chat.id === activeChatId ? "active" : ""
              }`}
              onClick={() => {
                loadChat(chat.id);
              }}
            >
              {chat.title}

              {/* <button className="more-button">
              <img
                className="image-button-size"
                src={moreImage}
                alt={"moreImage"}
              />
            </button> */}
            </div>
            <Popover.Root>
              <Popover.Trigger asChild>
                <button
                  key={chat.id}
                  className={`more-button chat-history-item ${
                    chat.id === activeChatId ? "active" : ""
                  } `}
                  aria-label="Update dimensions"
                >
                  <img
                    className="image-button-size"
                    src={moreImage}
                    alt={"moreImage"}
                  />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content className="PopoverContent" sideOffset={5}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <button
                      className="delete-chat"
                      type="button"
                      onClick={() => deleteChat(chat.id)}
                    >
                      Delete
                    </button>
                  </div>
                  {/* <Popover.Close className="PopoverClose" aria-label="Close">
               <Cross2Icon />
              </Popover.Close> */}
                  <Popover.Arrow className="PopoverArrow" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        ))}
      </div>

      <Dialog.Root>
        {session?.user?.user_metadata.paid_session ? (
          <Dialog.Trigger asChild>
            <button className="menu-footer-content" onClick={fetchData}>
              <Image source={starImage} alternate={"starImage"} />
              My Subscriptions
            </button>
          </Dialog.Trigger>
        ) : (
          ""
        )}

        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent DialogCustomWidth">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>

              <h2>My Subscription - Monthly</h2>
              {loading && (
                <div className="subscription-panel">
                  <div className="gridMysubs">
                    <div className="subscription-item">
                      <h3>Used Credits</h3>
                      <p>Loading...</p>
                    </div>
                    <div className="subscription-item">
                      <h3>Remaining Credits Monthly</h3>
                      <p>Loading...</p>
                    </div>
                    <div className="subscription-item">
                      <h3>Monthly Duration Period: </h3>
                      <p>Loading...</p>
                    </div>
                    <div className="subscription-item">
                      <h3>Price</h3>
                      <p>Loading...</p>
                    </div>
                  </div>
                </div>
              )}
              {error && <p className="error-message">Error: {error}</p>}
              {data && (
                <div className="subscription-panel">
                  <div className="gridMysubs">
                    <div className="subscription-item">
                      <h3>Used Credits</h3>
                      <p>{data.running_credits.toFixed(6)}</p>
                    </div>
                    <div className="subscription-item">
                      <h3>Remaining Credits Monthly</h3>
                      <p>{data.total_credits.toFixed(6)}</p>
                    </div>
                    <div className="subscription-item">
                      <h3>Monthly Duration Period:</h3>
                      <p>
                        From:{" "}
                        {formatDate(new Date(data.start_date).toLocaleString())}
                      </p>
                      <p>
                        To:{" "}
                        {formatDate(
                          new Date(data.expiry_date).toLocaleString()
                        )}
                      </p>
                    </div>
                    <div className="subscription-item">
                      <h3>Price</h3>
                      <p>${(data.price / 100).toFixed(2)}</p>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Dialog.Close asChild></Dialog.Close>
              </div>
              <Dialog.Close asChild>
                <button className="IconButton" aria-label="Close">
                  <img
                    src={crossicon}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",

                      filter: "invert(1)",
                    }}
                  />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>

      <Dialog.Root>
        {session?.user?.user_metadata.paid_session ? (
          <button className="menu-footer-content green-color" disabled>
            <Image source={starImage} alternate={"starImage"} />
            Subscribed to Premium version
          </button>
        ) : (
          <Dialog.Trigger asChild>
            <button className="menu-footer-content green-color">
              <Image source={starImage} alternate={"starImage"} />
              Upgrade to Premium version
            </button>
          </Dialog.Trigger>
        )}

        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent DialogCustomWidth">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>

              <SubsPanel />

              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Dialog.Close asChild></Dialog.Close>
              </div>
              <Dialog.Close asChild>
                <button className="IconButton" aria-label="Close">
                  <img
                    src={crossicon}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",

                      filter: "invert(1)",
                    }}
                  />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>
      {/* </div> */}

      {session && (
        <button className="menu-footer-content" onClick={handleLogout}>
          <Image source={logoutImage} alternate={"logoutImage"} />
          Logout
        </button>
      )}
    </div>
  );
};

export default Sidebar;
