import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Divider,
  CssBaseline,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Typography,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import Settings from "./Settings";
import { height, styled, useTheme } from "@mui/system";
import NavigationBar from "../screens/Navigationbar";
// import "src/chatwindow.css";
import {
  InputTextProps,
  ChatHistory,
  Message,
} from "../components/SendMessage";
import ChatComponent from "../components/ChatComponent";
import Sidebar from "./SideBar";
import SettingsPanel from "./SettingsPanel";
import axios from "axios";
import { AuthProvider, useAuth } from "./AuthContext";
import { useAppContext } from "./LLMContext";

interface ContainerProps {
  leftOpen: boolean;
  rightOpen: boolean;
}
const drawerWidth = 306;
const Root = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  backgroundColor: "#121212",
});

const TopAppBar = styled(AppBar)({
  zIndex: 1201,
  backgroundColor: "#1f1f1f",
});

const SecondaryAppBar = styled(AppBar)({
  top: "64px",
  zIndex: 1200,
  backgroundColor: "#1f1f1f",
});

const ContentContainer = styled("div")<{
  leftOpen: boolean;
  rightOpen: boolean;
}>(({ leftOpen, rightOpen }) => ({
  display: "flex",
  flexGrow: 1,
  margin: "70px 20px 0 20px", // Adjust based on the height of both app bars
  transition:
    "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin-right 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",

  marginLeft: leftOpen ? `${drawerWidth}px` : "0px",
  // marginRight: rightOpen ? `${drawerWidth}px` : "0px",
  backgroundColor: "#1f1f1f",
}));

const MainContent = styled("main")<{
  leftOpen: boolean;
  rightOpen: boolean;
}>(({ leftOpen, rightOpen }) => ({
  flexGrow: 1,
  padding: "16px 16px 0",
  transition:
    "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin-right 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",

  backgroundColor: "#121212",
}));

const SideDrawer = styled(Drawer)<{
  variant: "persistent";
  anchor: "left" | "right";
  open: boolean;
}>(({ theme, anchor }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    marginTop: "0", // Start just below the two app bars
    [theme.breakpoints.up("sm")]: {
      top: "0",
    },
    backgroundColor: "#222",
  },
  zIndex: 1100,
}));
/* separated style component */
/*menu*/
const IconContainer1 = styled("div")<{
  leftOpen: boolean;
}>(({ leftOpen }) => ({
  position: "absolute",
  left: "0",
  top: "74px",
  bottom: "0",
  padding: "8px",
  // zIndex: 1201,
  color: "#F6F7F9",
}));

/*settings*/
const IconContainer2 = styled("div")<{
  rightOpen: boolean;
}>(({ rightOpen }) => ({
  position: "absolute",
  right: "0",
  top: "74px",
  padding: "8px",
  // zIndex: 1201,
  color: "#F6F7F9",
}));

export function CloseButton({ handler, rightORleft }) {
  return (
    <a type="button" className={rightORleft} onClick={handler}>
      X
    </a>
  );
}

const ChatScreenWindow: React.FC<InputTextProps> = ({ disabled }) => {
  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
  } = useAppContext();
  // const storedModel = sessionStorage.getItem("selectedModel");
  // const storedCreator = sessionStorage.getItem("selectedCreator");
  // useEffect(() => {
  //   if (storedModel) {
  //     setSelectedModel(storedModel);
  //   }
  //   if (storedCreator) {
  //     setSelectedCreator(storedCreator);
  //   }
  //   sessionStorage.removeItem("selectedCreator");
  //   sessionStorage.removeItem("selectedModel");
  // }, []);

  const { session } = useAuth();

  const user_id = session?.user?.user_metadata.user_id;
  const navigate = useNavigate();

  const theme = useTheme();
  const [leftOpen, setLeftOpen] = React.useState(false);
  const [rightOpen, setRightOpen] = React.useState(false);
  // const [chats, setChats] = useState<ChatHistory[]>([]);

  //this will save the chats to local/ using cache
  const [chats, setChats] = useState<ChatHistory[]>(() => {
    const savedChats = localStorage.getItem("chats");
    return savedChats ? JSON.parse(savedChats) : [];
  });
  useEffect(() => {
    localStorage.setItem("chats", JSON.stringify(chats));
  }, [chats]);

  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const initialLoad = useRef(true);
  const location = useLocation();
  const fromWelcomeScreen = location.state?.fromWelcomeScreen;
  useEffect(() => {
    const chatExists = chats.length > 0;
    const chatInitialized =
      sessionStorage.getItem("chatInitialized") === "true";
    if (
      !fromWelcomeScreen &&
      initialLoad.current &&
      !chatExists &&
      !chatInitialized
    ) {
      initialLoad.current = false;
      // Find the maximum existing ID
      const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);
      const newChat: ChatHistory = {
        // thread_id: 263,
        id: maxId + 1,
        title: `Chat ${maxId + 1}`,
        messages: [
          // {
          //   id: 0,
          //   text: "Hello! How can I assist you today?",
          //   role: "assistant",
          // },
        ],
        creator: selectedCreator,
        model: selectedModel,
        systemInstructions: systemInstructions,
        temperature: temperature,
        maxTokens: maxTokens,
        stopSequences: stopSequences,
        //set to optional for Anthropic creator
        topP: topP,
        frequencyPenalty: frequencyPenalty,
        presencePenalty: presencePenalty,
      };
      setChats((prevChats) => [...prevChats, newChat]);
      setSelectedChatId(newChat.id);
      sessionStorage.setItem("chatInitialized", "true");
      sessionStorage.removeItem("selectedCreator");
      sessionStorage.removeItem("selectedModel");
    }
  }, []);

  const handleLeftDrawerToggle = () => {
    setLeftOpen(!leftOpen);
  };

  const handleRightDrawerToggle = () => {
    setRightOpen(!rightOpen);
  };

  const updateChatMessages = (
    chatId: number,
    messages: Message[],
    creator: string,
    model: string,
    systemInstructions: string,
    temperature: number,
    maxTokens: number,
    stopSequences: string[],
    topP?: number,
    frequencyPenalty?: number,
    presencePenalty?: number
  ) => {
    const updatedChats = chats.map((chat) => {
      // console.log("yachatId", chatId);
      // console.log("yamessages", messages);
      // console.log("yacreator", creator);
      // console.log("yamodel", model);
      // console.log("yasystemInstructions", systemInstructions);
      // console.log("yatemperature", temperature);
      // console.log("yamaxTokens", maxTokens);
      // console.log("yastopSequences", stopSequences);
      // console.log("yatopP", topP);
      // console.log("yafrequencyPenalty", frequencyPenalty);
      // console.log("yapresencePenalty", presencePenalty);

      if (chat.id === chatId) {
        return {
          ...chat,
          messages: messages === null ? [...chat.messages] : messages,
          creator,
          model,
          systemInstructions,
          temperature,
          maxTokens,
          stopSequences,
          topP,
          frequencyPenalty,
          presencePenalty,
        };
      }
      return chat;
    });
    setChats(updatedChats);
  };

  const handleNewChat = useCallback(() => {
    // Find the maximum existing ID
    const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);

    const newChat: ChatHistory = {
      // thread_id: 263,
      id: maxId + 1,
      title: `Chat ${maxId + 1}`,
      messages: [],
      creator: "OpenAI",
      model: "gpt-4o-mini",
      systemInstructions: "",
      temperature: 1,
      maxTokens: 100,
      stopSequences: [],
      //set to optional for Anthropic creator
      topP: 1,
      frequencyPenalty: 0,
      presencePenalty: 0,
    };
    // setSelectedModel(newChat.model);
    // setSelectedCreator(newChat.creator);
    setChats((prevChats) => [...prevChats, newChat]);
    setSelectedChatId(newChat.id);
  }, [chats.length]);

  const [isDeletingChats, setIsDeletingChats] = useState(false);
  const hasCreatedInitialChat = useRef(false);

  const deleteChat = async (chatId: number) => {
    setIsDeletingChats(true);

    const threadChatId = await fetchChatThreadID(chatId, user_id);
    console.log("chatThreadIDchatThreadID", threadChatId);

    if (threadChatId) {
      const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
      console.log("urlEnv", urlEnv);
      try {
        const response = await axios.post(`${urlEnv}delete-chat-db`, {
          chatId: chatId,
          chatThreadID: threadChatId,
          user_id: user_id,
        });
        if (response) {
          console.log(response.data);
        } else {
          console.log("chat from DB deleted");
        }
      } catch (error) {
        console.log(error);
      }
    }

    const updatedChats = chats.filter((chat) => chat.id !== chatId);
    setChats(updatedChats);
    console.log("chatIdchatIdchatId", chatId);
    if (selectedChatId === chatId) {
      setSelectedChatId(
        updatedChats.length > 0
          ? updatedChats[updatedChats.length - 1].id
          : null
      );
    }
    setIsDeletingChats(false);
  };

  const prevChatsLength = useRef(chats.length);
  useEffect(() => {
    if (chats.length < prevChatsLength.current) {
      chats.filter((chat) => {
        if (chat.id === selectedChatId) {
          setSelectedModel(chat.model);
          setSelectedCreator(chat.creator);
          setSystemInstructions(chat.systemInstructions);
          setTemperature(chat.temperature);
          setMaxTokens(chat.maxTokens);
          setStopSequences(chat.stopSequences);
          setTopP(chat.topP);
          setFrequencyPenalty(chat.frequencyPenalty);
          setPresencePenalty(chat.presencePenalty);
        }
      });
    }

    prevChatsLength.current = chats.length; // Update the ref with the current length after the effect runs
  }, [chats.length]);

  const fetchChatThreadID = async (chat_id, user_id) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "getThreadId";

    const response = await axios.get(urlEnv, {
      params: {
        chat_id: chat_id,
        user_id: user_id,
      },
    });

    console.log(response.data.thread_id);

    if (response.data) {
      return response.data.thread_id;
    } else if (!response.data) {
      return undefined;
    }
  };

  const [systemInstructions, setSystemInstructions] = useState<string>(() => {
    // const savedSystemInstructions =
    //   sessionStorage.getItem("systemInstructions");
    return "";
    // avedSystemInstructions || "";
  });
  const [temperature, setTemperature] = useState<number>(() => {
    // const savedTemperature = sessionStorage.getItem("temperature");
    return 1;
    // ? parseInt(savedTemperature) > 1
    //   ? 1
    //   : parseInt(savedTemperature)
    // : 1;
  });
  const [maxTokens, setMaxTokens] = useState<number>(() => {
    // const savedMaxTokens = sessionStorage.getItem("maxTokens");
    return 100;
    // savedMaxTokens !== null ? parseInt(savedMaxTokens) : 12;
  });

  const [topP, setTopP] = useState<number>(() => {
    // const savedTopP = sessionStorage.getItem("topP");
    return 1;
    // savedTopP !== null ? parseFloat(savedTopP) : 1;
  });

  const [stopSequences, setStopSequences] = useState<string[]>(() => {
    // const savedStopSequences = sessionStorage.getItem("stopSequences");
    return [];
    //savedStopSequences ? JSON.parse(savedStopSequences) : [];
  });

  const [frequencyPenalty, setFrequencyPenalty] = useState<number>(() => {
    // const savedFrequencyPenalty = sessionStorage.getItem("frequencyPenalty");
    return 0;
    //  savedFrequencyPenalty !== null
    //   ? parseFloat(savedFrequencyPenalty)
    //   : 0;
  });

  const [presencePenalty, setPresencePenalty] = useState<number>(() => {
    // const savedPresencePenalty = sessionStorage.getItem("presencePenalty");
    return 0;
    //savedPresencePenalty !== null ? parseFloat(savedPresencePenalty) : 0;
  });

  const handleSelectChat = (chatId: number) => {
    setSelectedChatId(chatId);
    console.log("chat.id", chatId);
    chats.filter((chat) => {
      if (chat.id === chatId) {
        setSelectedModel(chat.model);
        setSelectedCreator(chat.creator);
        setSystemInstructions(chat.systemInstructions);
        setTemperature(chat.temperature);
        setMaxTokens(chat.maxTokens);
        setStopSequences(chat.stopSequences);
        setTopP(chat.topP);
        setFrequencyPenalty(chat.frequencyPenalty);
        setPresencePenalty(chat.presencePenalty);
      }
    });
  };
  // useEffect(() => {
  //   sessionStorage.setItem("systemInstructions", systemInstructions);
  // }, [systemInstructions]);

  // useEffect(() => {
  //   sessionStorage.setItem("temperature", temperature.toString());
  // }, [temperature]);

  // useEffect(() => {
  //   sessionStorage.setItem("maxTokens", maxTokens.toString());
  // }, [maxTokens]);

  // useEffect(() => {
  //   sessionStorage.setItem("topP", topP.toString());
  // }, [topP]);

  // useEffect(() => {
  //   sessionStorage.setItem("frequencyPenalty", frequencyPenalty.toString());
  // }, [frequencyPenalty]);

  // useEffect(() => {
  //   sessionStorage.setItem("presencePenalty", presencePenalty.toString());
  // }, [presencePenalty]);

  const [hasSentInitialMessage, setHasSentInitialMessage] = useState(() => {
    return sessionStorage.getItem("hasSentInitialMessage") === "true";
  });
  const [isChatLoaded, setIsChatLoaded] = useState(false);
  const sendMessageButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (
      !hasSentInitialMessage &&
      fromWelcomeScreen &&
      isChatLoaded &&
      sendMessageButtonRef.current
    ) {
      sendMessageButtonRef.current.click();

      sessionStorage.setItem("hasSentInitialMessage", "true");
    }
  }, [fromWelcomeScreen, isChatLoaded]);

  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

  useEffect(() => {
    const selectedChats = chats.filter((chat) => chat.id === selectedChatId);

    selectedChats.map((chat, index) => {
      if (chat.messages && chat.messages.length > 0) {
        setIsDropdownDisabled(true);
      } else {
        setIsDropdownDisabled(false);
      }
    });
  }, [selectedChatId, chats]);

  return (
    <>
      <Root>
        <CssBaseline />
        <TopAppBar position="fixed">
          <NavigationBar />
          {/* Include NavigationBar inside the top app bar */}
        </TopAppBar>
        {/* menu button */}
        <IconContainer1 leftOpen={leftOpen}>
          {!leftOpen ? (
            <IconButton
              color="inherit"
              aria-label="open left drawer"
              onClick={handleLeftDrawerToggle}
            >
              {!leftOpen ? <MenuIcon /> : ""}
              {/*<MenuIcon />*/}
            </IconButton>
          ) : (
            ""
          )}{" "}
        </IconContainer1>
        {/* seting button */}
        {/* <IconContainer2 rightOpen={rightOpen}>
          {!rightOpen ? (
            <IconButton
              color="inherit"
              aria-label="open right drawer"
              onClick={handleRightDrawerToggle}
            >
              {!rightOpen ? <SettingsIcon /> : ""}
              {/*<SettingsIcon />
            </IconButton>
          ) : (
            ""
          )}
        </IconContainer2> */}

        <ContentContainer leftOpen={leftOpen} rightOpen={rightOpen}>
          <SideDrawer variant="persistent" anchor="left" open={leftOpen}>
            <CloseButton
              handler={handleLeftDrawerToggle}
              rightORleft={"close-left"}
            />
            <Sidebar
              chats={chats}
              activeChatId={selectedChatId}
              startNewChat={handleNewChat}
              loadChat={handleSelectChat}
              deleteChat={deleteChat}
            />
          </SideDrawer>

          {/* message content */}
          <MainContent leftOpen={leftOpen} rightOpen={rightOpen}>
            <Container style={{ height: "100%", width: "900px" }}>
              <ChatComponent
                selectedChatId={selectedChatId}
                chats={chats}
                updateChatMessages={updateChatMessages}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                sendMessageButtonRef={sendMessageButtonRef}
                onChatLoaded={() => setIsChatLoaded(true)}
                onOpenSettings={handleRightDrawerToggle}
                //
                systemInstructions={systemInstructions}
                temperature={temperature}
                maxTokens={maxTokens}
                topP={topP}
                stopSequences={stopSequences}
                frequencyPenalty={frequencyPenalty}
                presencePenalty={presencePenalty}
                disabled={isDropdownDisabled}
              />
            </Container>
          </MainContent>

          <SideDrawer variant="persistent" anchor="right" open={rightOpen}>
            <CloseButton
              handler={handleRightDrawerToggle}
              rightORleft={"close-right"}
            />
            <SettingsPanel
              // onSettingsChange={handleSettingsChange}
              systemInstructions={systemInstructions}
              setSystemInstructions={setSystemInstructions}
              temperature={temperature}
              setTemperature={setTemperature}
              maxTokens={maxTokens}
              setMaxTokens={setMaxTokens}
              topP={topP}
              setTopP={setTopP}
              stopSequences={stopSequences}
              setStopSequences={setStopSequences}
              frequencyPenalty={frequencyPenalty}
              setFrequencyPenalty={setFrequencyPenalty}
              presencePenalty={presencePenalty}
              setPresencePenalty={setPresencePenalty}
              disabled={isDropdownDisabled}
              startNewChat={handleNewChat}
            />
          </SideDrawer>
        </ContentContainer>
      </Root>
      {/* <Dialog.Root
        open={isOpen1}
        onOpenChange={(open) => {
          // if (!open) {
          //   handleDropdownClick();
          // }
          setIsOpen1(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>
        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <Dialog.Description className="DialogDescription"></Dialog.Description>

            <div className="subscription-panel">
              <h2>Unable to change Model</h2>
              <p className="description">
                Please open a new chat to select this model.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild></Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img
                  src={crossicon}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",

                    filter: "invert(1)",
                  }}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root> */}
    </>
  );
};

export default ChatScreenWindow;
export {
  ContentContainer,
  IconContainer1,
  SideDrawer,
  IconContainer2,
  Root,
  TopAppBar,
  MainContent,
};
