import React, { useEffect, useState } from "react";
import "src/styles.css";
import NavigationBar from "../screens/Navigationbar";
import WelcomeSection from "../screens/WelcomeSection";
import AppFooter from "../screens/Footer";
import logo from "../assets/logo.png";
import { styled } from "@mui/system";
import eyeopen from "src/assets/eye-regular.svg";
import eyeclose from "src/assets/eye-slash-regular.svg";
import { ErrorImage } from "./Login";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import * as Dialog from "@radix-ui/react-dialog";
import crossicon from "src/assets/cross-2.svg";
import SubscriptionPanel from "./SubscriptionPanel";
// import { useSession } from "./SessionProvider";

// need to change height
export const Containerdiv = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // flexDirection: "column",
  alignContent: "center",
  // height: "645px",
  // padding: "64px 10px",
  // height: "42vh",
});

export const Formdiv = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  padding: "10px",
  //   border: "1px solid #ccc",
  borderRadius: "5px",
  boxShadow: " 0 0 10px rgba(0, 0, 0, 0.1)",
  width: "400px",
});

export const Titlediv = styled("h2")({
  margin: "20px 0",
  textAlign: "start",
});

export const Labeldiv = styled("label")({
  margin: "2.5px 0",
  fontSize: "0.9em",
  color: "white",
  display: "flex",
});

export const Inputdiv = styled("input")({
  padding: "10px",
  borderRadius: "5px",
  border: "1px",
  backgroundColor: "#3a3a3a",
  color: "white",
  width: [
    "-webkit-fill-available",
    "-moz-available",
    "fill-available",
    "100%",
  ].join(" ; "),
  outline: "none", //remove
});

export const Linkdiv = styled("a")({
  //   fontSize: "1em",
  color: "#00cc66",
  textDecoration: "none",
  marginLeft: "auto",
  "&:hover": {
    textDecoration: "none",
  },
});

export const Buttondiv = styled("button")({
  padding: "10px",
  margin: "5px 0",
  height: "48px",
  border: "none",
  borderRadius: "5px",
  backgroundColor: "#00cc66",
  color: "white",
  fontSize: "1em",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#00b359",
  },
});

export const GoogleButtondiv = styled(Buttondiv)({
  backgroundColor: "white",
  //   height: "48px",
  color: "#444",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
});

export const Footerdiv = styled("div")({
  textAlign: "center",
  fontSize: "0.8em",
  color: "white",
  margin: "5px 0",
});

export const PasswordContainerdiv = styled("div")({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignContent: "flex-start",
  justifyContent: "center",
  alignItems: "stretch",
});

export const PasswordInputdiv = styled(Inputdiv)({
  flex: 1,
});

export const ToggleButtondiv = styled("button")({
  // position: "absolute",
  bottom: "7px",
  right: "10px",
  background: "none",
  border: "none",
  color: "#00aaff",
  cursor: "pointer",
  width: "30px",
  height: "auto",
  alignSelf: "Center",
});

interface SignupForm {
  email: string;
  password: string;
  confirmPassword: string;
}

const Signup: React.FC = () => {
  // const userSession = useSession();
  // const { checkSession } = useSession() || {};
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  // const { session, setSession } = useAuth();
  // useEffect(() => {
  //   if (session) {
  //     navigate("/chat");
  //   }
  // }, [session, navigate]);

  const [form, setForm] = useState<SignupForm>({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<SignupForm>({
    email: "",
    password: "",
    confirmPassword: "",
  });

  // useEffect(() => {
  //   if (userSession.userSession != null) {
  //     navigate("/chat"); //navigate to chats
  //   }
  // }, [checkSession, navigate]);

  const validateEmail = async (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      return "Email address is required";
    } else {
      // Wait for the checkemail function to complete
      console.log("calling checkemail");
      const exist = await checkemail(email);
      console.log("exist", exist);
      if (exist) {
        return "Email address already taken";
      }
    }

    if (!re.test(email)) {
      return "Invalid email address";
    }

    return undefined;
  };

  const checkemail = async (email) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "checkemail";
    console.log(email);
    console.log(urlEnv);

    const response = await axios.post(
      urlEnv,
      {
        email: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("response", response);
    console.log("response", response.data);
    if (!response.data) {
      return undefined;
    } else if (response.data) {
      return response.data;
    }
  };

  const validatePassword = (password: string): string => {
    if (!password) {
      return (password = "Password is required");
    }
    if (!(password.length >= 12)) {
      return "Password must be at least 12 characters";
    }
    return undefined;
  };
  const validateConfirmPassword = (confirmPassword: string): string => {
    if (confirmPassword !== form.password) {
      return "Passwords do not match";
    }

    return undefined;
  };
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emailError = await validateEmail(form.email);
    const passwordError = validatePassword(form.password);
    const confirmPasswordError = validateConfirmPassword(form.confirmPassword);

    if (emailError || passwordError || confirmPasswordError) {
      setErrors({
        email: emailError,
        password: passwordError,
        confirmPassword: confirmPasswordError,
      });
    } else {
      setErrors({
        email: undefined,
        password: undefined,
        confirmPassword: undefined,
      });

      // console.log("Form submitted successfully", form);
      // backend code here to database
      //call signup from supabase
      const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "signup";
      console.log("url", urlEnv);
      try {
        const response = await axios.post(urlEnv, {
          //chatId: selectedChatId,
          email: form.email,
          password: form.password,
        });

        console.log("Response data", response.data.message);

        if (response.data.message) {
          openDialog();
        } else if (!response.data.message) {
          alert(
            "supabase error: Email rate limit exceeded / email confirmation already sent"
          );
        }
        //alert("data is saved");
      } catch (error) {
        if (error.response) {
          console.error("Error saving query:", error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error setting up request:", error.message);
        }
      }
    }
  };

  const handleDialogClose = () => {
    navigate("/");
  };

  return (
    <>
      <NavigationBar />

      <div className="content-signup-login">
        <div className="signup-login">
          <div className="welcome-section2">
            <h1>Welcome to Panels AI</h1>&nbsp;
            <img src={logo} alt="Logo" className="welcome-logo" />
          </div>
        </div>

        <Containerdiv>
          <Formdiv onSubmit={handleSubmit}>
            <Titlediv>Create an account</Titlediv>
            <div className="flex-div-column">
              <Labeldiv htmlFor="email">Email</Labeldiv>
              <Inputdiv
                className={errors.email ? "input-error" : ""}
                type="text"
                id="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
              {errors.email && (
                <label className="error">
                  <ErrorImage />
                  {errors.email}
                </label>
              )}
            </div>
            <PasswordContainerdiv>
              <Labeldiv htmlFor="password" style={{ marginTop: "12px" }}>
                Password
              </Labeldiv>
              <div
                className={`password-input ${
                  errors.password ? "input-error" : ""
                }`}
              >
                <Inputdiv
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />

                <ToggleButtondiv
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <img
                    src={showPassword ? eyeopen : eyeclose}
                    alt="toggle visibility"
                  />
                </ToggleButtondiv>
              </div>
              {errors.password && (
                <label className="error">
                  <ErrorImage />
                  {errors.password}
                </label>
              )}
            </PasswordContainerdiv>
            <PasswordContainerdiv>
              <Labeldiv htmlFor="confirmPassword" style={{ marginTop: "12px" }}>
                Confirm password
              </Labeldiv>
              <div
                className={`password-input ${
                  errors.confirmPassword ? "input-error" : ""
                }`}
              >
                <Inputdiv
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={form.confirmPassword}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />

                <ToggleButtondiv
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <img
                    src={showConfirmPassword ? eyeopen : eyeclose}
                    alt="toggle visibility"
                  />
                </ToggleButtondiv>
              </div>
              {errors.confirmPassword && (
                <label className="error">
                  <ErrorImage />
                  {errors.confirmPassword}
                </label>
              )}
            </PasswordContainerdiv>
            <Buttondiv type="submit">Create account</Buttondiv>
            {/* <GoogleButtondiv type="button" disabled>
              <img
                src="https://img.icons8.com/color/16/000000/google-logo.png"
                alt="Google icon"
              />
              Continue with Google
            </GoogleButtondiv> */}
            <Footerdiv>
              Already Have An Account ? <Linkdiv href="/">Log In</Linkdiv>
            </Footerdiv>
          </Formdiv>
        </Containerdiv>
      </div>
      <AppFooter />

      <Dialog.Root
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            handleDialogClose();
          }
          setIsOpen(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>
        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <Dialog.Description className="DialogDescription"></Dialog.Description>

            <div className="subscription-panel">
              <h2>Email Verification sent</h2>
              <p className="description">
                Please confirm your email to start using PanelsAI.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild></Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img
                  src={crossicon}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",

                    filter: "invert(1)",
                  }}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default Signup;
