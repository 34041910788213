import React from "react";
import "../styles.css";
import starImage from "src/assets/star.png";
import { Image } from "../components/SideBar";
import SettingsPanel from "../components/SettingsPanel";
import "src/modal.css";
import crossicon from "src/assets/cross-2.svg";
import * as Dialog from "@radix-ui/react-dialog";
import SubscriptionPanel from "../components/SubscriptionPanel";
import { useAuth } from "../components/AuthContext";

const AppFooter: React.FC = () => {
  const { session } = useAuth();

  return (
    <div className="footer">
      {/*
<a type="button" className="welcome-advance-settings">
<Image source={settingImage} alternate={"settingImage"} />
Advance Settings
</a>
<hr /> */}
      <Dialog.Root>
        {session?.user?.user_metadata.paid_session ? (
          <a href="#" className="green-color ">
            <Image source={starImage} alternate={"starImage"} />
            Subscribed to Premium version
          </a>
        ) : (
          <Dialog.Trigger asChild>
            <a href="#" className="green-color ">
              <Image source={starImage} alternate={"starImage"} />
              Upgrade to Premium version
            </a>
          </Dialog.Trigger>
        )}

        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <Dialog.Description className="DialogDescription"></Dialog.Description>

            <SubscriptionPanel />

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild></Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img
                  src={crossicon}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",

                    filter: "invert(1)",
                  }}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      {/* <a href="#">Privacy Policy</a>
      <a href="#">Terms & Conditions</a>
      <a href="#">Affiliate Programs</a>
      <a href="#">API</a> */}
    </div>
  );
};

export default AppFooter;
