import React, { useState, useEffect, Component } from "react";
import "../chatscreen.css";
import { margin } from "@mui/system";
import Dropdownllm from "./Llm";
import { useAppContext } from "./LLMContext";
import { DropdownllmProps } from "./Llm";
import Anthropic from "@anthropic-ai/sdk";

interface SettingsPanelProps2nd {
  systemInstructions?: string;
  setSystemInstructions?: React.Dispatch<React.SetStateAction<string>>;
  temperature?: number;
  setTemperature?: React.Dispatch<React.SetStateAction<number>>;
  maxTokens?: number;
  setMaxTokens?: React.Dispatch<React.SetStateAction<number>>;
  topP?: number;
  setTopP?: React.Dispatch<React.SetStateAction<number>>;
  stopSequences?: string[];
  setStopSequences?: React.Dispatch<React.SetStateAction<string[]>>;
  frequencyPenalty?: number;
  setFrequencyPenalty?: React.Dispatch<React.SetStateAction<number>>;
  presencePenalty?: number;
  setPresencePenalty?: React.Dispatch<React.SetStateAction<number>>;
  disabled?: boolean;
  startNewChat: () => void;
  withSystemTOF?: boolean;
}

const SettingsPanel: React.FC<SettingsPanelProps2nd> = ({
  // onSettingsChange,
  systemInstructions,
  setSystemInstructions,
  temperature,
  setTemperature,
  maxTokens,
  setMaxTokens,
  topP,
  setTopP,
  stopSequences,
  setStopSequences,
  frequencyPenalty,
  setFrequencyPenalty,
  presencePenalty,
  setPresencePenalty,
  disabled,
  startNewChat,
  withSystemTOF,
}) => {
  // const [systemInstructions, setSystemInstructions] = useState("");
  // const [temperature, setTemperature] = useState(25);
  // const [maxTokens, setMaxTokens] = useState(12);
  // const [topP, setTopP] = useState(1);
  // const [stopSequences, setStopSequences] = useState("");
  // const [frequencyPenalty, setFrequencyPenalty] = useState(0);
  // const [presencePenalty, setPresencePenalty] = useState(0);

  const handleSettingsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "systemInstructions":
        setSystemInstructions && setSystemInstructions(value);
        break;
      case "temperature":
        setTemperature && setTemperature(Number(value));
        break;
      case "maxTokens":
        setMaxTokens && setMaxTokens(Number(value));
        break;
      case "topP":
        setTopP && setTopP(Number(value));
        break;
      case "stopSequences":
        setCurrentInput && setCurrentInput(value);
        break;
      case "frequencyPenalty":
        setFrequencyPenalty && setFrequencyPenalty(Number(value));
        break;
      case "presencePenalty":
        setPresencePenalty && setPresencePenalty(Number(value));
        break;
      default:
        break;
    }
  };

  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
  } = useAppContext();

  const [currentInput, setCurrentInput] = useState<string>("");
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Tab" && currentInput.trim() !== "") {
      e.preventDefault(); // Prevent default tab behavior
      if (stopSequences.length < 4) {
        setStopSequences([...stopSequences, currentInput.trim()]);
        setCurrentInput(""); // Clear the input after adding
      }
    }
  };

  const handleDeleteSequence = (index: number) => {
    setStopSequences(stopSequences.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setSystemInstructions("");
    setTemperature(1);
    setMaxTokens(100);
    setTopP(1);
    setStopSequences([]);
    setFrequencyPenalty(0);
    setPresencePenalty(0);
  }, [startNewChat]);

  return (
    <div className="settings-panel">
      <div className="margin-t">
        <Dropdownllm
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          selectedCreator={selectedCreator}
          setSelectedCreator={setSelectedCreator}
          selectedLLM={selectedLLM}
          setSelectedLLM={setSelectedLLM}
          disabled={disabled}
        />
      </div>
      <div className="settings-panel-scroll messages-container">
        <div className="h-100vh">
          <div className="setting-col ">
            <label className="label-pad pb-0" htmlFor="systemInstructions">
              System
            </label>
            {/* {disabled ? (
            <label className="label-pad sequence-text-size">
              Changing the System Instruction when conversation starts is
              prohibited, you have to open a new chat
            </label>
          ) : (
            ""
          )} */}

            {/*input tag was changed to textarea*/}

            <textarea
              className="setting-textarea"
              placeholder="Enter system instruction"
              id="systemInstructions"
              name="systemInstructions"
              value={systemInstructions}
              // onChange={(e) => setSystemInstructions(e.target.value)}
              onChange={handleSettingsChange}
              // readOnly={disabled}
            ></textarea>
          </div>
          <div className="setting-col">
            <label htmlFor="max-tokens" className="label-pad">
              Maximum Tokens<span className="text-right">{maxTokens}</span>
            </label>
            <input
              type="range"
              className="color-accent"
              id="max-tokens"
              min="1"
              max="1024"
              name="maxTokens"
              value={maxTokens}
              // onChange={(e) => setMaxTokens(parseInt(e.target.value, 10))}
              onChange={handleSettingsChange}
            />
          </div>
          <div className="setting-col">
            <label htmlFor="temperature" className="label-pad">
              Temperature<span className="text-right">{temperature}</span>
            </label>

            <input
              type="range"
              className="color-accent"
              id="temperature"
              min="0"
              max={selectedCreator === "Anthropic" ? 1 : 2}
              step="0.1"
              name="temperature"
              value={temperature}
              // onChange={(e) => setTemperature(parseInt(e.target.value, 10))}
              onChange={handleSettingsChange}
            />
          </div>

          {selectedCreator === "OpenAI" ? (
            <div className="setting-col">
              <label htmlFor="top-p" className="label-pad">
                Top P<span className="text-right">{topP}</span>
              </label>
              <input
                type="range"
                className="color-accent"
                id="top-p"
                min="0"
                max="1"
                step="0.1"
                name="topP"
                value={topP}
                //onChange={(e) => setTopP(parseFloat(e.target.value))}
                onChange={handleSettingsChange}
              />
            </div>
          ) : (
            ""
          )}
          <div className="setting-col">
            <label htmlFor="stop-sequences" className="diff-pad">
              Stop Sequences
            </label>
            <label className="label-pad sequence-text-size">
              Enter sequence and press tab
            </label>

            {/* Displaying the current list of stop sequences */}
            <div className="sequence-list">
              {stopSequences.map((sequence, index) => (
                <div key={index} className="sequence-item">
                  {sequence}
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteSequence(index)}
                  >
                    X
                  </button>
                </div>
              ))}
            </div>

            {/* Textarea to input new sequences */}
            <textarea
              className="setting-textarea"
              id="stop-sequences"
              name="stopSequences"
              value={currentInput}
              onChange={handleSettingsChange}
              onKeyDown={handleKeyDown}
              placeholder="Type a sequence and press Tab"
              disabled={stopSequences.length >= 4} // Disable input if max sequences reached
            ></textarea>
          </div>
          {selectedCreator === "OpenAI" ? (
            <>
              <div className="setting-col">
                <label className="label-pad" htmlFor="frequency-penalty">
                  Frequency Penalty
                  <span className="text-right">{frequencyPenalty}</span>
                </label>
                <input
                  type="range"
                  className="color-accent"
                  id="frequency-penalty"
                  name="frequencyPenalty"
                  min="-2"
                  max="2"
                  step="0.1"
                  value={frequencyPenalty}
                  // onChange={(e) => setFrequencyPenalty(parseInt(e.target.value, 10))}
                  onChange={handleSettingsChange}
                />
              </div>
              <div className="setting-col">
                <label className="label-pad" htmlFor="presence-penalty">
                  Presence Penalty
                  <span className="text-right">{presencePenalty}</span>
                </label>
                <input
                  type="range"
                  className="color-accent"
                  id="presence-penalty"
                  name="presencePenalty"
                  min="-2"
                  max="2"
                  step="0.1"
                  value={presencePenalty}
                  // onChange={(e) => setPresencePenalty(parseInt(e.target.value, 10))}
                  onChange={handleSettingsChange}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="setting-col setting-col-footer">
          <footer className="setting-footer">
            <p className="s-f-paragraph">
              API request and other third-party integrations will not be used to
              train models.
            </p>
            <a href="#" className="s-f-paragraph green-color">
              Learn more
            </a>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default SettingsPanel;
